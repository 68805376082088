
import ApiService from "@/core/services/ApiService"

import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import AccountCardTypeComponent from "@/components/finance/definitions/account-card-type.vue"
import { Tag, TagType } from "@/core/models/Tag"
import { CirclePlus } from "@element-plus/icons-vue"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "account-card-type-list",
  components: { AccountCardTypeComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const newAccountCardType = ref(false)

    const AccountCardTypeList = ref<Tag[]>([] as Tag[])

    const activeAccountCardTypeId = ref<string | undefined>()

    const activeParentId = ref<string>()
    const activeParentName = ref<string>("")

    const treeProps = {
      id: "id",
      parentId: "parentId",
      children: "childs",
    }

    watch(
      () => props.add,
      newValue => {
        newAccountCardType.value = true == newValue
      }
    )

    watch(
      () => route.params.account_card_type_id,
      newValue => {
        activeAccountCardTypeId.value = newValue as string
      }
    )

    const getAccountCardTypeList = async () => {
      var config = {
        params: {
          tagType: TagType.AccountCardType,
        },
      }
      const { data } = await ApiService.query("tag/childs", config)
      AccountCardTypeList.value = data
    }

    async function removeAccountCardType(accountCardTypeId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) await ApiService.get("tag/remove/" + accountCardTypeId)
      await getAccountCardTypeList()
    }

    function handleAccountCardTypeDrawerClose() {
      activeParentId.value = undefined
      activeParentName.value = ""
      router.push({ name: "account-card-type-list" })
    }

    const OnClickChild = (parentId, parentName) => {
      activeParentId.value = parentId as string
      activeParentName.value = parentName
      router.push({ name: "account-card-type-add", params: { activeParentId: parentId } })
    }

    onMounted(async () => {
      activeAccountCardTypeId.value = route.params.account_card_type_id as string
      newAccountCardType.value = props.add
      await getAccountCardTypeList()
    })

    return {
      Edit,
      CirclePlus,
      removeAccountCardType,
      getAccountCardTypeList,
      activeAccountCardTypeId,
      newAccountCardType,
      handleAccountCardTypeDrawerClose,
      AccountCardTypeList,
      activeParentId,
      OnClickChild,
      treeProps,
      activeParentName,
    }
  },
})
